<template>
  <div class="container">
    <div class="post-datails-container">
      <post
        v-if="post"
        :item="post"
        @update-post="getPost"
      />
    </div>
  </div>
</template>
<script>

import Post from '@/components/profile-company/Post.vue'

export default {
  components: {
    Post,
  },
  computed: {
    post() {
      return this.$store.getters['posts/post']
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.id': {
      handler() {
        this.getPost()
      },
      deep: true,
    },
  },
  created() {
    this.getPost()
  },
  beforeDestroy() {
    this.$store.dispatch('posts/clearPost')
  },
  methods: {
    getPost() {
      this.$store.dispatch('posts/fetchPostById', this.$route.params.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.post-datails-container {
    width: 65%;
}
@media (max-width: 767px) {
    .post-datails-container {
        width: 100%;
    }
}
</style>
